import React from 'react';
import { Paper, NumberFormatter, Title, Flex, Text, Skeleton } from '@mantine/core';
import { DashboardItem } from '../typing';
import { DashboardLoading } from './DashboardLoading';

type Props = {
  title: string;
  value: number;
  description?: string | { singular: string; plural: string };

  // Data Props
  loading?: boolean;
  lastRequest?: Date;
  source?: DashboardItem<void>['source'];
};

export const DashboardNumberCard: React.FC<Props> = (props) => {
  const getDescription = React.useCallback(() => {
    if (!props.description) return '';

    // Checking if singular string
    if (typeof props.description === 'string') return props.description;

    // Checking if multiple strings
    if (props.value === 1) return props.description.singular;
    return props.description.plural;
  }, [props.description]);

  return (
    <Paper p="xl" radius="md" className="relative bg-primary-900 text-white" shadow="sm">
      <DashboardLoading {...props} inverted />
      <Text size="md">{props.title}</Text>
      <Flex align="baseline" gap="xs">
        <Skeleton visible={props.loading} radius="xs" width={props.loading ? 100 : undefined}>
          <Title order={1}>
            <NumberFormatter value={props.value} thousandSeparator="." decimalSeparator="," />
          </Title>
          {props.description && <Text size="xs">{getDescription()}</Text>}
        </Skeleton>
      </Flex>
    </Paper>
  );
};
