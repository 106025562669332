import React, { useState } from 'react';
import {
  Grid,
  Text,
  Title,
  Paper,
  Select,
  SegmentedControl,
  useMantineTheme,
  Flex,
  Transition,
  GridColProps,
  Box,
  Loader,
} from '@mantine/core';
import { DashboardItem, DashboardProps, DateValue } from './typing';
import { DashboardMissionGrid } from './components/DashboardMissionGrid';
import { DashboardTagList } from './components/DashboardTagList';
import { DashboardUserList } from './components/DashboardUserList';
import { DashboardBarChart } from './components/DashboardBarChart';
import { DashboardNumberCard } from './components/DashboardNumberCard';
import { timeRanges } from './utils';
import { useMediaQuery } from '@mantine/hooks';
import { DashboardAssistantList } from './components/DashboardAssistantList';
import dynamic from 'next/dynamic';

// Dynamically importing the notification banner to avoid ssr
const NotificationBanner = dynamic(
  () =>
    import('../NotificationBanner/NotificationBanner').then(
      (element) => element.NotificationBanner,
    ),
  {
    ssr: false,
  },
);

export default function Dashboard(props: DashboardProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [timeRange, setTimeRange] = useState<DateValue>(props.initialDateInterval || 'last7Days');

  const FilterComponent = React.useMemo(() => (isMobile ? Select : SegmentedControl), [isMobile]);

  const shouldShowColumn = React.useCallback(
    (item: DashboardItem<unknown[] | { date: unknown[] }>) => {
      if (!item.value) return true;
      const hasItems =
        'date' in item.value
          ? Array.isArray(item.value.date) && item.value.date.length > 0
          : Array.isArray(item.value) && item.value.length > 0;
      if (item.loading || hasItems) return true;
      return false;
    },
    [],
  );

  return (
    <>
      {props.hasData && (
        <Flex justify="flex-end" pt="md">
          <FilterComponent
            size="xs"
            data={timeRanges}
            value={timeRange}
            color="primary"
            onChange={(value) => {
              setTimeRange(value as DateValue);
              if (props.onDateChange) props.onDateChange(value as DateValue);
            }}
          />
        </Flex>
      )}

      {/* Notification banner */}
      {props.showNotificationBanner && (
        <Box py="md">
          <NotificationBanner />
        </Box>
      )}

      <Grid grow>
        {props.hasData ? (
          <>
            {/* Big numbers */}
            <AnimatedGridCol span={{ base: 12, sm: 6 }}>
              <DashboardNumberCard
                {...props.threadAndMessageCountByDate}
                title="Total de Conversas"
                value={props.threadAndMessageCountByDate.value.total.threadCount}
              />
            </AnimatedGridCol>
            <AnimatedGridCol span={{ base: 12, sm: 6 }}>
              <DashboardNumberCard
                {...props.threadAndMessageCountByDate}
                title="Total de Mensagens"
                value={props.threadAndMessageCountByDate.value.total.messageCount}
              />
            </AnimatedGridCol>

            {/* Line chat of threads per day */}

            <AnimatedGridCol
              span={{ base: 12, md: 6 }}
              hidden={!shouldShowColumn(props.threadAndMessageCountByDate)}
            >
              <DashboardBarChart
                {...props.threadAndMessageCountByDate}
                dataKey="threadCount"
                data={props.threadAndMessageCountByDate.value.date}
                title="Conversas"
              />
            </AnimatedGridCol>

            <AnimatedGridCol
              span={{ base: 12, md: 6 }}
              hidden={!shouldShowColumn(props.threadAndMessageCountByDate)}
            >
              <DashboardBarChart
                {...props.threadAndMessageCountByDate}
                dataKey="messageCount"
                data={props.threadAndMessageCountByDate.value.date}
                title="Mensagens"
              />
            </AnimatedGridCol>

            {/* Threads per User */}
            <AnimatedGridCol
              span={{ base: 12, lg: 4 }}
              hidden={!shouldShowColumn(props.threadCountByUser)}
            >
              <DashboardUserList
                {...props.threadCountByUser}
                threadCountByUser={props.threadCountByUser.value}
              />
            </AnimatedGridCol>

            {/* Threads per Tag */}
            <AnimatedGridCol
              span={{ base: 12, lg: 4 }}
              hidden={!shouldShowColumn(props.threadCountByTag)}
            >
              <DashboardTagList
                {...props.threadCountByTag}
                allTags={props.allTags}
                threadCountByTag={props.threadCountByTag.value}
              />
            </AnimatedGridCol>

            {/* Mission GRID */}
            <AnimatedGridCol
              span={{ base: 12, lg: 4 }}
              hidden={!shouldShowColumn(props.threadCountByMission)}
            >
              <DashboardMissionGrid
                {...props.threadCountByMission}
                threadCountByMission={props.threadCountByMission.value}
              />
            </AnimatedGridCol>
          </>
        ) : (
          // Empty Message
          <>
            <AnimatedGridCol span={{ base: 12 }} hidden={props.loadingAssistants}>
              <Paper
                p="xl"
                radius="md"
                className="bg-white"
                shadow="sm"
                mt={props.showNotificationBanner ? 0 : 'md'}
              >
                <Title order={2} className="text-primary-600">
                  Bem-vindo ao Agento!
                </Title>
                <Text>
                  Parece que você ainda não tem informações suficientes para exibirmos. Quando o
                  assistente começar os atendimentos, você verá suas estatísticas aqui.
                </Text>
              </Paper>
            </AnimatedGridCol>

            {/* Very first loader */}
            <AnimatedGridCol span={{ base: 12 }} hidden={!props.loadingAssistants}>
              <Flex className="w-full" justify="center" align="center" h={300}>
                <Loader type="bars" size="lg" />
              </Flex>
            </AnimatedGridCol>
          </>
        )}

        {/* Assistant List */}
        <AnimatedGridCol
          span={{ base: 12 }}
          hidden={!props.assistants || props.assistants.length < 1}
        >
          <DashboardAssistantList
            assistants={props.assistants}
            loading={!!props.loadingAssistants}
          />
        </AnimatedGridCol>
      </Grid>
    </>
  );
}

export function AnimatedGridCol({ hidden, children, span, ...props }: GridColProps) {
  return (
    <Transition mounted={!hidden} transition="fade-down" duration={200} timingFunction="ease">
      {(styles) => (
        <Grid.Col span={span} style={styles} {...props}>
          {children}
        </Grid.Col>
      )}
    </Transition>
  );
}
