import React from 'react';
import {
  Group,
  Paper,
  Title,
  Text,
  Flex,
  useMantineTheme,
  Badge,
  Skeleton,
  Spoiler,
} from '@mantine/core';
import { IconRocket, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { DashboardItem, MissionThreadCount } from '../typing';
import { DashboardLoading } from './DashboardLoading';
import Link from 'next/link';
import { IoMagnetOutline } from '@react-icons/all-files/io5/IoMagnetOutline';
import { IoMegaphoneOutline } from '@react-icons/all-files/io5/IoMegaphoneOutline';
import { capitalizeFirstLetter, spoilerMaxHeight } from '../utils';

type Props = {
  threadCountByMission: MissionThreadCount[];

  // Data Props
  loading?: boolean;
  lastRequest?: Date;
  source?: DashboardItem<void>['source'];
};

const getEngagementTypeColor = (type: string) => {
  // Same color as src/views/Missions/components/MissionForm.tsx
  switch (type.toLowerCase()) {
    case 'inbound':
      return 'blue';
    case 'outbound':
      return 'blue';
    default:
      return 'gray';
  }
};

export const DashboardMissionGrid: React.FC<Props> = (props) => {
  const theme = useMantineTheme();

  return (
    <Paper p="xl" radius="md" shadow="sm" className="bg-white relative">
      <DashboardLoading {...props} />
      <Group mb="md">
        <Title order={3} className="text-primary-600">
          Missões atuais
        </Title>
        <IconRocket size={28} color={theme.colors.primary[6]} />
      </Group>
      <Skeleton visible={props.loading} radius="xs" height={props.loading ? 100 : undefined}>
        <Spoiler
          maxHeight={spoilerMaxHeight} // Same on all components on the same row
          showLabel={<IconChevronDown />}
          hideLabel={<IconChevronUp />}
          classNames={{ control: 'left-1/2' }}
        >
          <Flex direction="column" gap="sm">
            {props.threadCountByMission.map((mission) => (
              <Link href={`/missoes/${mission.id}`} key={mission.id}>
                <Paper
                  key={mission.id}
                  p="md"
                  radius="md"
                  shadow="none"
                  className="bg-gray-50 border border-gray-200 transition-all duration-300 hover:shadow-md hover:bg-white"
                >
                  <Flex justify="space-between" align="center" mb="xs">
                    <Title order={4} className="text-secondary-700">
                      {mission.title}
                    </Title>
                  </Flex>
                  <Group gap="xs" mb="xs">
                    <Badge
                      color={getEngagementTypeColor(mission.activityEngagementType)}
                      variant="light"
                      leftSection={
                        mission.activityEngagementType.toLowerCase() === 'inbound' ? (
                          <IoMagnetOutline
                            size={12}
                            color={getEngagementTypeColor(mission.activityEngagementType)}
                          />
                        ) : (
                          <IoMegaphoneOutline
                            size={12}
                            color={getEngagementTypeColor(mission.activityEngagementType)}
                          />
                        )
                      }
                    >
                      {capitalizeFirstLetter(mission.activityEngagementType)}
                    </Badge>
                    <Text size="sm" c="dimmed">
                      {mission.activityName}
                    </Text>
                  </Group>
                  <Flex align="baseline" gap="xs" className="mt-4">
                    <Text size="xl" fw={700} className="text-primary-500">
                      {mission.threadCount}
                    </Text>
                    <Text size="sm" c="dimmed">
                      {mission.threadCount === 1 ? 'conversa' : 'conversas'}
                    </Text>
                  </Flex>
                </Paper>
              </Link>
            ))}
          </Flex>
        </Spoiler>
      </Skeleton>
    </Paper>
  );
};
