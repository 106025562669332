import React from 'react';
import {
  Paper,
  Group,
  Title,
  Flex,
  Text,
  useMantineTheme,
  Table,
  Spoiler,
  Skeleton,
} from '@mantine/core';
import { IconUsers, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { DashboardItem, UserThreadCount } from '../typing';
import { DashboardLoading } from './DashboardLoading';
import { User } from '@/typings/user';
import { CHAT_FILTERS } from '@/utils/storageKeys';
import Link from 'next/link';
import { spoilerMaxHeight } from '../utils';

type Props = {
  threadCountByUser: UserThreadCount[];

  // Data Props
  loading?: boolean;
  lastRequest?: Date;
  source?: DashboardItem<void>['source'];
};

export const DashboardUserList: React.FC<Props> = (props) => {
  const theme = useMantineTheme();

  const onClick = React.useCallback((userId: User['id']) => {
    // Setting filters before navigating to new page
    const filters = {
      tagIds: [],
      userIds: [userId],
      assistantIds: [],
    };
    localStorage.setItem(CHAT_FILTERS, JSON.stringify(filters));
  }, []);

  const sortedData = React.useMemo(() => {
    return props.threadCountByUser.sort((user1, user2) => user2.threadCount - user1.threadCount);
  }, [props.threadCountByUser]);

  return (
    <Paper p="xl" radius="md" className="bg-white relative" shadow="sm">
      <DashboardLoading {...props} />
      <Group mb="md">
        <Title order={3} className="text-primary-600">
          Atividade por Usuário
        </Title>
        <IconUsers size={24} color={theme.colors.primary[6]} />
      </Group>
      <Skeleton visible={props.loading} radius="xs" height={props.loading ? 100 : undefined}>
        <div className="">
          <Spoiler
            maxHeight={spoilerMaxHeight}
            showLabel={<IconChevronDown />}
            hideLabel={<IconChevronUp />}
            classNames={{ control: 'left-1/2' }}
          >
            <Table>
              <Table.Tbody>
                {sortedData.map((user) => (
                  <Table.Tr key={user.id} className="hover:bg-gray-50 transition-colors">
                    <Table.Td colSpan={2} className="p-0">
                      <Link
                        href="/conversas"
                        onClick={() => onClick(user.id)}
                        className="block w-full h-full no-underline text-inherit"
                      >
                        <Flex p="sm" justify="space-between" align="center">
                          <Text>{user.name}</Text>
                          <Flex align="baseline" gap="xs">
                            <Text fw={700}>{user.threadCount}</Text>
                            <Text size="xs">
                              {user.threadCount === 1 ? 'conversa' : 'conversas'}
                            </Text>
                          </Flex>
                        </Flex>
                      </Link>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Spoiler>
        </div>
      </Skeleton>
    </Paper>
  );
};
