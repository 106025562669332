import React from 'react';
import { Tag } from '@/typings/backend';
import { Paper, Group, Title, Text, useMantineTheme, Skeleton, Flex, Spoiler } from '@mantine/core';
import { IconTags, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { DashboardItem, TagThreadCount } from '../typing';
import { DashboardLoading } from './DashboardLoading';
import Link from 'next/link';
import { CHAT_FILTERS } from '@/utils/storageKeys';
import { spoilerMaxHeight } from '../utils';

type Props = {
  threadCountByTag: TagThreadCount[];
  allTags: Tag[];

  // Data Props
  loading?: boolean;
  lastRequest?: Date;
  source?: DashboardItem<void>['source'];
};

export const DashboardTagList: React.FC<Props> = (props) => {
  const theme = useMantineTheme();

  const getTagTitle = React.useCallback(
    (tagId: string) => {
      const tag = props.allTags.find((t) => t.id === tagId);
      return tag ? tag.shortTitle : 'Etiqueta Desconhecida';
    },
    [props.allTags],
  );

  const onClick = React.useCallback((tagId: Tag['id']) => {
    const filters = {
      tagIds: [tagId],
      userIds: [],
      assistantIds: [],
    };
    localStorage.setItem(CHAT_FILTERS, JSON.stringify(filters));
  }, []);

  const loadingTags = React.useMemo(() => {
    return props.loading || props.allTags.length < 1;
  }, [props.loading, props.allTags]);

  const sortedTags = React.useMemo(() => {
    if (loadingTags) return []; // Missing tag titles
    return [...props.threadCountByTag].sort((a, b) => b.threadCount - a.threadCount);
  }, [props.threadCountByTag]);

  const maxThreadCount = React.useMemo(() => {
    return Math.max(...sortedTags.map((tag) => tag.threadCount));
  }, [sortedTags]);

  return (
    <Paper p="xl" radius="md" className="bg-white relative" shadow="sm">
      <DashboardLoading {...props} />
      <Group mb="md">
        <Title order={3} className="text-primary-600">
          Atividade por Etiqueta
        </Title>
        <IconTags size={24} color={theme.colors.primary[6]} />
      </Group>
      <Skeleton visible={loadingTags} radius="xs" height={loadingTags ? 300 : undefined}>
        <Spoiler
          maxHeight={spoilerMaxHeight} // Same on all components on the same row
          showLabel={<IconChevronDown />}
          hideLabel={<IconChevronUp />}
          classNames={{ control: 'left-1/2' }}
        >
          {sortedTags.map((tag) => (
            <Link key={tag.id} href="/conversas" onClick={() => onClick(tag.id)}>
              <div className="mb-2 rounded-md overflow-hidden">
                <Flex align="center" className="relative h-8">
                  <Text fw={500} className="absolute left-2 z-10 text-secondary-700">
                    {getTagTitle(tag.id)}
                  </Text>
                  <div className="bg-white h-full w-full overflow-hidden relative rounded-md hover:bg-gray-100 transition-colors ">
                    <div
                      className="bg-primary-100 hover:bg-primary-200 h-full transition-all duration-500 ease-out absolute left-0 rounded-md"
                      style={{ width: `${(tag.threadCount / maxThreadCount) * 100}%` }}
                    />
                    <Flex
                      align="baseline"
                      gap="xs"
                      justify="flex-end"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10"
                    >
                      <Text fw={700} c="secondary">
                        {tag.threadCount}
                      </Text>
                      <Text size="xs">{tag.threadCount === 1 ? 'conversa' : 'conversas'}</Text>
                    </Flex>
                  </div>
                </Flex>
              </div>
            </Link>
          ))}
        </Spoiler>
      </Skeleton>
    </Paper>
  );
};
