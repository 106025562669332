import React from 'react';
import { Paper, Box, Title, useMantineTheme, Skeleton, Text } from '@mantine/core';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  TooltipProps,
} from 'recharts';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DashboardLoading } from './DashboardLoading';
import { DashboardItem } from '../typing';
import { capitalizeFirstLetter } from '../utils';

dayjs.extend(localizedFormat);
dayjs.locale('pt-br');

type DataItem<Key extends string> = {
  [key in Key]: number;
} & {
  date: string;
};

type Props<Key extends string> = {
  data: DataItem<Key>[];
  dataKey: Key;
  title: string;

  // Data Props
  loading?: boolean;
  lastRequest?: Date;
  source?: DashboardItem<void>['source'];
};

export function DashboardBarChart<T extends string>(props: Props<T>) {
  const theme = useMantineTheme();

  // Determine if we need to group by month
  const shouldGroupByMonth = props.data.length > 40;

  // Group data by month if necessary, otherwise use daily data
  const groupedData = React.useMemo(() => {
    if (!shouldGroupByMonth) return props.data;

    const monthlyData = props.data.reduce<Record<string, { [key: string]: number }>>(
      (acc, curr) => {
        const monthKey = dayjs(curr.date).format('YYYY-MM');
        if (!acc[monthKey]) {
          acc[monthKey] = {
            date: monthKey,
            [props.dataKey]: 0,
          } as DataItem<T>;
        }
        acc[monthKey][props.dataKey] += curr[props.dataKey];
        return acc;
      },
      {},
    );

    return Object.values(monthlyData) as DataItem<T>[];
  }, [props.data, props.dataKey, shouldGroupByMonth]);

  // Find extremes of the dataset
  const [min, max] = React.useMemo(() => {
    let min = Infinity;
    let max = -Infinity;
    groupedData.forEach((item) => {
      const data = item[props.dataKey];
      if (data < min) min = data;
      if (data > max) max = data;
    });
    return [min, max];
  }, [groupedData, props.dataKey]);

  // Get bar color for each value on the scale
  const getBarColor = React.useCallback(
    (value: number) => {
      const normalizedValue = (value - min) / (max - min);
      const index = Math.floor((1 - normalizedValue) * 9); // Change 1-X to X to invert the colors
      return theme.colors.primary[index] || theme.colors.primary[4]; // Fallback
    },
    [min, max, theme.colors.primary],
  );

  const formatXAxis = (dateString: string) => {
    const date = dayjs(dateString);
    if (!date.isValid()) return dateString;
    return shouldGroupByMonth
      ? capitalizeFirstLetter(date.format('MMM YYYY'))
      : date.format('DD/MM');
  };

  return (
    <Paper radius="md" className="bg-white relative" shadow="sm">
      <DashboardLoading {...props} />
      <Box px="xl" pt="xl" pb="sm">
        <Title order={3} mb="xs" className="text-primary-600">
          {props.title} por {shouldGroupByMonth ? 'mês' : 'dia'}
        </Title>
      </Box>
      <Box pb="xl" pr="xl" pl={props.loading ? 'xl' : undefined}>
        <Skeleton visible={props.loading} radius="xs">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={groupedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis yAxisId="left" allowDecimals={false} />
              <Tooltip content={<CustomTooltip dataKey={props.dataKey} />} />
              <Bar
                yAxisId="left"
                type="monotone"
                dataKey={props.dataKey}
                fill={theme.colors.primary[4]}
                name={props.title}
              >
                {groupedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getBarColor(entry[props.dataKey])} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Skeleton>
      </Box>
    </Paper>
  );
}

const CustomTooltip = ({
  active,
  payload,
  label,
  dataKey,
}: TooltipProps<number, string> & { dataKey: string }) => {
  if (active && payload && payload.length) {
    const value = payload[0].value as number;
    const formattedLabel = dayjs(label).isValid()
      ? capitalizeFirstLetter(dayjs(label).format('D [de] MMMM [de] YYYY'))
      : label;

    let displayText = '';
    if (dataKey.toLowerCase().includes('thread')) {
      displayText = value === 1 ? '1 conversa' : `${value} conversas`;
    } else if (dataKey.toLowerCase().includes('message')) {
      displayText = value === 1 ? '1 mensagem' : `${value} mensagens`;
    } else {
      displayText = `${value} ${dataKey.toLowerCase()}`;
    }

    return (
      <Paper shadow="md" p="md" withBorder>
        <Text size="sm" pb="sm">
          {formattedLabel}
        </Text>
        <Text size="xl" c={payload[0].color} fw={500}>
          {displayText}
        </Text>
      </Paper>
    );
  }

  return null;
};
