import React from 'react';
import { useWebsocket } from '@/providers/Websocket/WebsocketProvider';
import { Button, Flex, Group, Paper, Skeleton, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Assistant } from '@/typings/backend';
import { DashboardLoading } from './DashboardLoading';
import { IconRobot, IconMessageChatbot } from '@tabler/icons-react';

type AssistantCardProps = {
  assistant: Assistant;
};

type Props = {
  assistants: Assistant[];
  loading: boolean;
};

export const DashboardAssistantList: React.FC<Props> = (props) => {
  const theme = useMantineTheme();
  return (
    <Paper p="xl" radius="md" className="bg-white relative" shadow="sm">
      <DashboardLoading loading={props.loading} source={props.loading ? 'storage' : 'online'} />
      <Group mb="md">
        <Title order={3} className="text-primary-600">
          Assistentes
        </Title>
        <IconRobot size={28} color={theme.colors.primary[6]} />
      </Group>
      <Skeleton visible={props.loading} radius="xs" height={props.loading ? 100 : undefined}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {props.assistants?.map((assistant) => (
            <AssistantCard assistant={assistant} key={assistant.id} />
          ))}
        </div>
      </Skeleton>
    </Paper>
  );
};

export const AssistantCard: React.FC<AssistantCardProps> = ({ assistant }) => {
  const theme = useMantineTheme();
  const { startNewChat, isConnected } = useWebsocket();

  return (
    <Paper
      p="md"
      shadow="none"
      radius="md"
      className="bg-gray-50 border border-gray-200 transition-all duration-300 hover:shadow-md hover:bg-white"
    >
      <Flex justify="flex-start" align="center" mb="md" gap="md">
        <div className="rounded-full w-[30px] h-[30px] flex items-center justify-center border border-secondary-600">
          <IconMessageChatbot color={theme.colors.secondary[5]} size={20} />
        </div>
        <Title order={4} className="text-secondary-700">
          {assistant.name}
        </Title>
      </Flex>
      <Button
        fullWidth
        variant="outline"
        onClick={() => {
          if (!assistant.defaultMissionId) {
            notifications.show({
              title: 'Este assistente ainda não tem uma missão padrão',
              message:
                'Configure-o para indicar qual missão deve ser utilizada nas conversas iniciadas',
              color: 'red',
            });
            return;
          }

          startNewChat({ assistantId: assistant.id, makeChatFloating: true });
        }}
        disabled={!isConnected}
      >
        Conversa de teste
      </Button>
    </Paper>
  );
};
