import { type DateValue } from './typing';

type TimeRanges = { value: DateValue; label: string }[];

export const timeRanges: TimeRanges = [
  { value: 'today', label: 'Hoje' },
  { value: 'thisWeek', label: 'Esta semana' },
  { value: 'last7Days', label: 'Últimos 7 dias' },
  { value: 'thisMonth', label: 'Este mês' },
  { value: 'last30Days', label: 'Últimos 30 dias' },
  { value: 'all', label: 'Tudo' },
];

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const spoilerMaxHeight = 40 * 8; // 40px is the item size
