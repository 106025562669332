import React from 'react';
import { Loader, Tooltip, useMantineTheme } from '@mantine/core';
import { format } from 'date-fns';

interface LoadingIndicatorProps {
  loading?: boolean;
  source?: 'online' | 'storage' | null;
  lastRequest?: Date;
  inverted?: boolean;
}

export const DashboardLoading: React.FC<LoadingIndicatorProps> = ({
  source,
  lastRequest,
  inverted = false,
}) => {
  const theme = useMantineTheme();

  if (source === 'online') {
    // Hide all icons
    return null;
  }

  const getIconColor = () => {
    if (source === 'storage') return theme.colors.primary[0];
    if (inverted) return theme.white; // Dark background
    return theme.colors.primary[6]; // Light background
  };

  return (
    <div className="absolute top-2 right-2 z-10">
      <Tooltip
        label={
          source === 'storage'
            ? `Dados do armazenamento local. Última atualização: ${
                lastRequest ? format(new Date(lastRequest), 'dd/MM/yyyy HH:mm:ss') : 'Desconhecida'
              }`
            : 'Atualizando informações'
        }
        position="bottom"
        withArrow
      >
        <Loader size={12} color={getIconColor()} type="bars" />
      </Tooltip>
    </div>
  );
};
